import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

import SEO from "../components/seo"

import { Text } from "../components/Core"
import { Heading } from "../components/Hero"
import TopSection from "./about/TopSection"
import OurMission from "./about/OurMission"
import MeetOurDoctors from "./about/MeetOurDoctors"
import OurReviews from "./about/OurReviews"
import MeetOurTeam from "./about/MeetOurTeam"
import ReasonsWhy from "./about/ReasonsWhy"
import BannerSection from "./about/BannerSection"
import { Columns, Section } from "../components/Containers"
import { CallToAction } from "../components/CTA"
import TheaterVideo from "@components/Theater/TheaterVideo"

const AboutUsPage = ({ pageContext, location, data }) => {
  const post = data.allUniquePagesJson.nodes[0]
  const { metaTitle, metaDescription, language } = pageContext

  const team = data.team.nodes[0].meetOurTeam.staffImages
  return (
    <Layout language={language}>
      <SEO
        title={metaTitle}
        description={metaDescription}
        pathname={location.pathname}
        lang={language}
      />
      <div className="joshua-tree-content about-us">
        {/* <Heading heading={post.heading} blurb={post.topBlurb} /> */}
        <div className="about-us__hero">
          <Columns sideColumnsSize={3}>
            <div className="column is-half-desktop">
              <Text className="px-40--mobile" as="h1" text={post.heading} />
              <Text
                useStringReplace
                as="p"
                className="mb-0 mt-2--mobile"
                text={post.topBlurb}
              />

              {/* <TheaterVideo
                buttonClass="contained mx-auto--mobile"
                videoUrl={`https://www.youtube.com/watch?v=${post.mainVideo.youtube}`}
                controls
                playing
                language={language}
              /> */}
            </div>
          </Columns>
        </div>

        <TopSection
          youtube={post.mainVideo.youtube}
          desktopImage={post.mainVideo.imageId}
          mobileImage={post.mainVideo.imageIdMobile}
          language={language}
        />
        <OurMission post={post} language={language} sideColumnIs={4} />
        <MeetOurDoctors post={post} language={language} colorBack />
        <OurReviews post={post} language={language} />
        <ReasonsWhy
          colorBack
          heading={post.reasonsWhySection.heading}
          hasThisSection={post.reasonsWhySection.hasThisSection}
          list={post.reasonsWhySection.reasonsWhyColumn}
          language={language}
          hasBorderedCards
        />

        <MeetOurTeam
          colorBack={language === "en"}
          post={post}
          team={team}
          language={language}
        />

        {post.bannerSection && post.bannerSection.hasThisSection && (
          <BannerSection
            text={post.bannerSection.text}
            image={post.bannerSection.logoId}
            // colorBack
          />
        )}
        <CallToAction
          // colorBack
          heading={post.callToAction.heading}
          blurb={post.callToAction.blurb}
          buttons={post.callToAction.buttons}
        />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query AboutPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        ourMission
        heading
        youtube
        topBlurb
        meetOurTeam {
          hasThisSection
          text
          showStaff
        }
        reasonsWhySection {
          hasThisSection
          heading
          reasonsWhyColumn {
            blurb
            heading
            number
          }
        }
        hearWhatTheyHaveToSay {
          hasThisSection
          heading
          useImageStrip
          videos {
            patients {
              caption
              imageId
              youtube
            }
            referringProviders {
              caption
              imageId
              youtube
            }
          }
          imageStrip {
            youtube
            images {
              imageId
            }
          }
        }
        meetOurDoctors {
          hasThisSection
          useButtons
          images {
            doctor {
              caption
              imageId
              url
            }
          }
          text
        }
        metaTitle
        metaDescription
        title
        mainVideo {
          imageId
          imageIdMobile
          youtube
        }
        bannerSection {
          hasThisSection
          logoId
          text
        }
        callToAction {
          heading
          blurb
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
        }
      }
    }
    team: allUniquePagesJson(
      filter: { template: { eq: "about-us" }, language: { eq: "en" } }
    ) {
      nodes {
        meetOurTeam {
          staffImages {
            staffMember {
              imageId
              staffName
              staffTitle
              spanishTitle
            }
          }
        }
      }
    }
  }
`

export default AboutUsPage
